<template>
  <ion-page class="ion-page">
    <go-back-header :defaultHref="`/facilities/${id}`" />

    <ion-content>
      <List
        :header="$t('transferdetails')"
        :loading="$apollo.loading && !getOrderTransfer"
        :not-found-message="`No transfer information available`"
      >
        <template  v-if="getOrderTransfer">
        <ion-item>
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding">{{ $t('email') }}</ion-col>
              <ion-col class="ion-no-padding">
                <ion-text color="moto"><p>{{ getOrderTransfer.order.user.email }}</p></ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding">{{ $t('firstname') }}</ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getOrderTransfer.order.user.first_name }}</p></ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding">{{ $t('lastname') }}</ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getOrderTransfer.order.user.last_name }}</p></ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding">{{ $t('dateofbirth') }}</ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getOrderTransfer.order.user.date_of_birth || '-' }}</p></ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>

        <ion-item class="ion-padding-top">
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding">{{ $t('title') }}</ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getOrderTransfer.title }}</p></ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding">{{ $t('type') }}</ion-col>
              <ion-col class="ion-no-padding">
                <ion-badge color="moto">{{ getOrderTransfer.type }}</ion-badge>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding">{{ $t('status') }}</ion-col>
              <ion-col class="ion-no-padding">
                <ion-badge
                  :color="(getOrderTransfer.status === 'paid') ? 'moto' : 'secondary'">{{ getOrderTransfer.status }}
                </ion-badge>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>

        <ion-item class="ion-padding-top">
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding">{{ $t('seller') }}</ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getOrderTransfer.order.seller.name || getOrderTransfer.order.seller.first_name }}</p></ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>

        <ion-item>
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding"></ion-col>
              <ion-col class="ion-no-padding">
                <ion-text color="moto"><p>{{ $t('count') }}</p></ion-text>
              </ion-col>
              <ion-col class="ion-no-padding">
                <ion-text color="moto"><p>{{ $t('value') }}</p></ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding"><ion-label>{{ $t('sales') }}</ion-label></ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getCount('sale') }}</p></ion-text>
              </ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getValue('sale') | stripeAmount }}</p></ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding"><ion-label>{{ $t('resales') }}</ion-label></ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getCount('resale') }}</p></ion-text>
              </ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getValue('resale') | stripeAmount }}</p></ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding"><ion-label>{{ $t('fees') }}</ion-label></ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getCount('fee') }}</p></ion-text>
              </ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getValue('fee') | stripeAmount }}</p></ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding"><ion-label>{{ $t('commissions') }}</ion-label></ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getCount('commission') }}</p></ion-text>
              </ion-col>
              <ion-col class="ion-no-padding">
                <ion-text><p>{{ getValue('commission') | stripeAmount }}</p></ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <ion-item>
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col size="4" size-sm="2" class="ion-no-padding"><ion-label color="moto">{{ $t('total') }}</ion-label></ion-col>
              <ion-col class="ion-no-padding"></ion-col>
              <ion-col class="ion-no-padding">
                <ion-text color="moto"><p>{{ getOrderTransfer.stripe_amount | stripeAmount }}</p></ion-text>
              </ion-col>
            </ion-row>
            <ion-row v-if="isRefunded">
              <ion-col size="4" size-sm="2" class="ion-no-padding"><ion-label color="danger">{{ $t('refunds') }}</ion-label></ion-col>
              <ion-col class="ion-no-padding"></ion-col>
              <ion-col class="ion-no-padding">
                <ion-text color="danger"><p>{{ -amountRefunded | stripeAmount }}</p></ion-text>
              </ion-col>
            </ion-row>
            <ion-row v-if="isRefunded">
              <ion-col size="4" size-sm="2" class="ion-no-padding"><ion-label color="moto">{{ $t('nettotal') }}</ion-label></ion-col>
              <ion-col class="ion-no-padding"></ion-col>
              <ion-col class="ion-no-padding">
                <ion-text color="moto"><p>{{ getOrderTransfer.stripe_amount - amountRefunded | stripeAmount }}</p></ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        </template>
      </List>

      <List
        :header="$t('lineitems')"
        :not-found-message="$t('nolineitemsfound')"
      >
        <template v-if="getOrderTransfer && getOrderTransfer.lineItems && getOrderTransfer.lineItems.length">
          <ion-item v-for="lineItem in getOrderTransfer.lineItems" :key="lineItem.id">
            <ion-grid>
              <ion-row>
                <ion-col size="8" size-sm="7">
                  <ion-text color="white"><p>{{ lineItem.name }}</p></ion-text>
                  <ion-badge :color="lineItemTypeMapping[lineItem.type].color">{{ $t(lineItemTypeMapping[lineItem.type].label) }}</ion-badge>
                </ion-col>
                <ion-col>
                  <s v-if="lineItem.refunded_at">
                    <ion-text color="danger"><p>{{ lineItem.stripe_amount | stripeAmount }}</p></ion-text>
                  </s>
                  <ion-text v-else color="white"><p>{{ lineItem.stripe_amount | stripeAmount }}</p></ion-text>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-text>
              <p>{{  }}</p>
            </ion-text>

          </ion-item>
        </template>
      </List>
    </ion-content>
  </ion-page>
</template>

<script>
  import GoBackHeader from '@/components/navigation/GoBackHeader.vue'
  import { GET_ORDER_TRANSFER } from '@/graphql/queries'


  const lineItemTypeMapping = {
    sale: {
      label: "sale",
      color: "moto",
    },
    fee: {
      label: "fee",
      color: "warning",
    },
    commission: {
      label: "commission",
      color: "medium"
    },
    resale: {
      label: "resale",
      color: "moto"
    },
  }

  export default {
    props: ['id', 'transferId'],
    components: {
      GoBackHeader
    },
    data() {
      return {
        lineItemTypeMapping
      }
    },
    computed: {
      isRefunded() {
        return ['refunded', 'partially_refunded'].includes(this.getOrderTransfer.status);
      },
      amountRefunded() {
        if (!this.isRefunded) {
          return 0;
        }

        if (this.getOrderTransfer.status === 'refunded') {
          return this.getOrderTransfer.stripe_amount;
        }

        return this.getOrderTransfer.lineItems.reduce((carry, item) => {
          return item.refunded_at ? (carry + item.stripe_amount) : carry;
        }, 0)
      }
    },
    apollo: {
      getOrderTransfer: {
        query: GET_ORDER_TRANSFER,
        variables() {
          return {
            facility_id: this.id,
            order_transfer_id: this.transferId,
            cursor: null
          }
        },
      },
    },
    methods: {
      getCount(type) {
        return this.getOrderTransfer.lineItems.reduce((carry, item) => {
          return item.type === type ? carry + 1 : carry;
        }, 0)
      },

      getValue(type) {
        return this.getOrderTransfer.lineItems.reduce((carry, item) => {
          return item.type === type ? carry + item.stripe_amount : carry;
        }, 0)
      }

    }
  }
</script>
